import Navbar from "../../../navigation/pages/Menu";

function Page() {
  return (
    <>
      <Navbar />
    </>
  );
}

export default Page;
